const module1Messages = {
  part1: {
    intro: {
      objectif1: 'Connaître les enjeux, objectifs et moyens d’action en matière de rénovation énergétique',
      objectif2: 'Savoir transmettre l’information à ses interlocuteurs',
      autoEvaluate: {
        title: 'auto-évaluation',
        question: 'Comment évaluez-vous vos connaissances sur les aides à la rénovation énergétique\xa0?',
        highLevel: 'Je suis expert(e)',
        lowLevel: 'J\'ai tout à apprendre',
        bubbleConfident: 'Vous avez l’air confiant(e)',
        bubbleNotConfident: 'Vous êtes modeste, non\xa0?',
        helpConfident: 'Vous pouvez passer directement à l’action ou revoir les bases',
        helpNotConfident: 'Vous allez vous sentir expert(e) ou presque dans quelques minutes\xa0!'
      }
    },
    lesson1: {
      tab: 'enjeux',
      title: 'Les enjeux de la rénovation énergétique',
      titleAnnotation: 'La rénovation énergétique des logements répond à divers enjeux de société :',
      block1: {
        title: 'La raréfaction des ressources impose la sobriété énergétique',
        annotation: 'La France consomme l’équivalent de 2,7 fois les ressources que la Terre peut générer en une année. Le secteur du bâtiment résidentiel et tertiaire consomme une part conséquente de ces ressources.',
        quizzLabel: 'Savez-vous',
        quizzQuestion: 'Quelle est la part des consommations d’énergie des bâtiments à l’échelle nationale\xa0?',
        quizzAnswerLow: '29 %',
        quizzAnswerMedium: '32 %',
        quizzAnswerHigh: '45 %',
        source: 'Bilan énergétique de la France pour 2019'
      },
      block2: {
        title: 'Le réchauffement climatique impose de réduire drastiquement les gaz à effet de serre',
        annotation: 'En France, 52% des logements sont chauffés au fioul ou au gaz. Ces énergies représentent 83% des émissions de gaz à effet de serre produites par le chauffage des logements.  ',
        quizzLabel: 'Savez-vous',
        quizzQuestion: 'Quelle est la part des émissions de gaz à effet de serre des bâtiments à l’échelle nationale ?',
        quizzAnswerLow: '10 %',
        quizzAnswerMedium: '18 %',
        quizzAnswerHigh: '31 %',
        source: 'Répartition sectorielle des émissions de gaz à effet de serre en France en 2019'
      },
      block3: {
        title: 'La précarité énergétique, un enjeu social et sanitaire',
        annotation: 'On parle de précarité énergétique quand une personne a des difficultés ou est dans l\'incapacité financière à chauffer son logement correctement. La précarité énergétique est généralement due à une mauvaise isolation et à la présence d’équipements peu performants.',
        quizzLabel: 'Savez-vous',
        quizzQuestion: 'Combien de français souffrent de précarité énergétique ?',
        quizzAnswerLow: '3 millions',
        quizzAnswerMedium: '6 millions',
        quizzAnswerHigh: '12 millions',
        source: 'ONPE'
      }
    },
    lesson2: {
      tab: 'objectifs',
      title: 'Les objectifs nationaux',
      titleAnnotation: 'Pour répondre à l’urgence de ces enjeux, la France s’est fixée des objectifs ambitieux\xa0:',
      block1: {
        passoire: {
          label: 'd’ici 2028',
          title: 'Éradiquer les passoires thermiques',
          annotation: 'soit 5,2 millions de logements'
        },
        bbc: {
          label: 'd’ici 2050',
          title: 'Rénover 100% du parc au niveau BBC',
          annotation: '30 millions de logements'
        },
        conso: {
          label: 'd’ici 2050',
          title: 'Réduire de moitié les consommations d’énergie des logements et atteindre la neutralité carbone'
        }
      },
      block2: {
        quizzLabel: 'Savez-vous',
        quizzQuestion: 'Quel est l\'objectif de rénovation performante en 2024 ?',
        quizzAnswerLow: '70 000',
        quizzAnswerMedium: '200 000',
        quizzAnswerHigh: '500 000'
      }
    },
    lesson3: {
      tab: 'moyens',
      title: 'Les moyens mis en place',
      titleAnnotation: 'Pour booster le nombre de rénovations performantes, l’État a mis en place\xa0:',
      block1: {
        title: 'Des mesures incitatives',
        content: 'Depuis 2005, l’État a mis en place différents <b>dispositifs d\'aides financières pour :<br>' +
          '- faciliter l’accès aux travaux de rénovation<br>' +
          '- encourager la réalisation de rénovations performantes et durables.<br></b>'
      },
      block2: {
        title: 'Des services d’accompagnement',
        content: '<b>Le réseau France Rénov’</b> a pour mission d’informer et d\'accompagner les ménages et les copropriétés dans leur projet de rénovation énergétique. Ce service public de la rénovation énergétique est présent sur tout le territoire avec 450 guichets animés par 1&nbsp;800 conseillers.'
      },
      block3: {
        title: 'Des obligations',
        content: 'À l\'issue de la Convention citoyenne pour le climat, l’État a instauré un calendrier pour <b>obliger les propriétaires à rénover les logements les moins performants.</b>',
        modal: {
          button: 'Consulter le calendrier d’application',
          label1: '24 août 2022',
          title1: 'Gel des loyers des passoires thermiques',
          label2: '1er janvier 2025',
          title2: 'Interdiction de mettre en location des logements classés G',
          label3: '1er janvier 2028',
          title3: 'Interdiction de mettre en location des logements classés F',
          label4: '1er janvier 2034',
          title4: 'Interdiction de mettre en location des logements classés E'
        }
      },
      block4: {
        title: 'En bref…',
        content: 'Bien que les bénéfices d’une rénovation massive du parc résidentiel sont évidents, <b>il ne faut pas oublier l’importance de l’accompagnement du public sur la question de la rénovation énergétique.</b> Pour informer sur les enjeux et les mesures mises en place, mais aussi pour initier aux principes de la sobriété énergétique, primordiale pour réduire durablement les consommations d’énergie.'
      },
      block5: {
        content: 'Voyons comment vous  transmettriez les informations et sensibiliseriez à la rénovation énergétique...'
      }
    },
    activity: {
      title: 'Conseillez votre interlocuteur',
      help: 'Répondez à toutes les questions de votre interlocuteur',
      success: 'Vous avez répondu à toutes les questions de votre interlocuteur\xa0!',
      label: 'votre réponse',
      feedbackLabel: 'à propos de votre réponse',
      userAnswers: {
        answer1: 'Non, mais à partir de 2028<br>' +
          'les loyers des logements classés F ne pourront plus être augmentés',
        answer2: 'C\'est vrai, à partir de 2028<br>' +
          'il sera interdit de mettre en location des logements classés F',
        answer3: 'Vous avez le droit de penser ce que vous voulez mais c’est la loi&nbsp;et votre maison est une passoire énergétique&nbsp;!',
        answer4: 'La loi Climat & Résilience a défini ces interdictions pour lutter contre les passoires énergétiques.',
        answer5: 'Ce sont les logements classés F ou G<br>' +
          'au diagnostic de performance énergétique.',
        answer6: 'Ce sont des logements qui<br>' +
          'consomment plus de 450 kWh/m².an<br>' +
          'et ne sont pas aux normes de décence.',
        answer7: 'Vous devez faire des travaux<br>' +
          'de rénovation énergétique.',
        answer8: 'Vous pouvez faire un audit énergétique pour définir les travaux adaptés à votre logement.',
        answer9: 'Il existe de nombreuses aides financières et des éco-prêts à taux zéro.',
        answer10: 'Il existe des aides mais<br>' +
          'c’est vrai que c’est compliqué…',
        answer11: 'Vous pouvez aller sur internet,<br>' +
          'il y a de nombreux simulateurs.',
        answer12: 'Nous pouvons prendre rendez-vous<br>' +
          'pour faire une visite de votre maison<br>' +
          'et établir un audit énergétique<br>' +
          'et un plan de financement&nbsp;!'
      },
      recipientAnswers: {
        answer1: 'Bonjour !',
        answer2: 'Ma maison est sortie en F au DPE…',
        answer3: 'Le diagnostiqueur m’a dit que je ne pourrai bientôt plus la louer, c’est vrai&nbsp;?',
        answer4: 'C’est un abus du droit de propriété, non&nbsp;?',
        answer5: 'Vous pouvez m’expliquer ce que c’est une passoire énergétique ?',
        answer7: 'Pas aux normes\xa0de décence&nbsp;!!<br>' +
          'Ça m’étonnerait&nbsp;?!',
        answer8: 'Et comment je fais pour que mon logement ne soit plus une passoire énergétique&nbsp;?',
        answer9: 'J’ai déjà dépensé beaucoup <br>' +
          'pour refaire les peintures…',
        answer10: 'J’ai déjà fait la peinture et changé le lino&nbsp;!<br>' +
          'Ça m’a couté assez cher&nbsp;! ',
        answer11: 'Comment est-ce que je peux financer les travaux de rénovation énergétique&nbsp;?',
        answer12: 'Comment savoir à quelles aides j’ai le droit&nbsp;?<br>' +
          'Personne ne peut m’aider&nbsp;?',
        answer13: '...',
        answer14: 'Ah merci, j’ai bien fait de vous appeler&nbsp;!'
      },
      feedbacks: {
        feedback1: 'La loi prévoit l’interdiction de louer des passoires énergétiques pas seulement le gel des loyers.',
        feedback2: 'Effectivement, la loi prévoit l’interdiction de louer des passoires énergétiques pas seulement le gel des loyers.',
        feedback3: 'La vérité n’est pas toujours bonne à entendre... S’il vous plait, faites un effort de pédagogie !',
        feedback4: 'Bravo pour votre effort de pédagogie !',
        feedback5: 'Oui, les passoires énergétiques sont des logements classés F ou G. Ils consomment plus de 330 kWh/m².an ou plus de 70 kgeqCO2/m².an',
        feedback6: 'Pas besoin d\'atteindre 450 kWh/m².an ! Les passoires énergétiques sont des logements classés F ou G. Ils consomment plus de 330 kWh/m².an ou plus de 70 kgeqCO2/m².an',
        feedback7: 'Effectivement, ce propriétaire va devoir rénover son logement mais il attend que vous le conseilliez. L’audit énergétique permet de définir les travaux de rénovation appropriés au logement.',
        feedback8: 'L’audit énergétique permet de définir les travaux de rénovation appropriés au logement.',
        feedback9: 'Les aides dépendent des travaux réalisés, du gain énergétique obtenu et du statut du bénéficiaire.',
        feedback10: 'Les aides sont variables selon les travaux réalisés, le gain énergétique obtenu et le statut du bénéficiaire mais ne démoralisez pas ce propriétaire...',
        feedback11: 'Vous êtes un conseiller France Rénov’, c’est votre mission d’aider ce propriétaire.'
      },
      intro: {
        title: 'Savoir transmettre avec pédagogie les informations liées à la rénovation énergétique',
        rules: 'Vous êtes conseiller France Rénov’ et un propriétaire vous contacte pour des renseignements. <b>Répondez à ses questions de la façon la plus pédagogique possible.</b>'
      }
    }
  },
  part2: {
    intro: {
      objectif1: 'Connaître l’éco-système des aides financières à la rénovation énergétique',
      autoEvaluate: {
        title: 'auto-évaluation',
        question: 'Comment évaluez-vous vos connaissances des aides financières à la rénovation énergétique\xa0?'
      }
    },
    tabs: {
      lesson1: 'aides d’état',
      lesson2: 'aides privées',
      lesson3: 'aides locales',
      lesson4: 'cumul'
    },
    goToActivity: 'Voyons si vous êtes capable de distinguer chacune des aides\xa0!',
    subTitles: {
      logement: 'Pour quel logement\xa0?',
      qui: 'Qui peut en bénéficier\xa0?',
      montant: 'Pour quel montant\xa0?',
      travaux: 'Pour quels travaux\xa0?',
      obtention: 'Comment l’obtenir\xa0?'
    },
    travaux: {
      isol: 'Isolation',
      menuiserie: 'Remplacement de fenêtres simple vitrage',
      chauffage: 'Remplacement de systèmes de chauffage et d’eau chaude',
      vmc: 'Ventilation double flux',
      vmcSimple: 'Ventilation',
      renoGlobale: 'Rénovation globale',
      renoAmpleur: 'Rénovation d’ampleur',
      travauxInduit: 'Et tous les travaux induits\xa0: ventilation, peinture, plomberie, électricité…'
    },
    aides: {
      maprimerenov: {
        title: 'MaPrimeRénov’',
        intro: '<b>MaPrimeRénov’, l’aide principale de l’Etat pour financer jusqu’à 63&nbsp;0000€</b> les travaux de rénovation énergétique d’un logement',
        logement: 'Pour tous les logements occupés en <b>résidence principale</> et achevés depuis <b>plus de 15 ans</b>',
        qui: 'Tous les propriétaires <b>occupants ou bailleurs</b> (hors Société Civile Individuelle)',
        montantIntro: 'MaPrimeRénov’ délivre 2 types d’aides&nbsp;:',
        montantRenoAmpleur: '- <b>des aides en pourcentage du coût HT des travaux pour des rénovations d’ampleur d’un montant maximal de 63&nbsp;000&nbsp;€</b>',
        montantRenoAmpleurModalButton: 'Voir les montants des aides à la rénovation d\'ampleur',
        montantGeste: '- <b>des aides forfaitaires pour les gestes de rénovation plafonnées</b> à 20&nbsp;000&nbsp;€ par logement et ne pouvant dépasser un pourcentage du coût de travaux',
        menageListIntro: '<b>L\'accès aux aides MaPrimeRenov` dépend du niveau de revenus du ménage</b>',
        menageModestePlus: 'les ménages aux <b>revenus très modestes</b> ont accès aux <b>2 types d\'aides</b>',
        menageModeste: 'les ménages aux <b>revenus modestes</b> ont accès aux <b>2 types d\'aides</b>',
        menageInter: 'les ménages aux <b>revenus intermédiaires</b> ont accès aux <b>2 types d\'aides</b>',
        menageSup: 'les ménages aux <b>revenus supérieurs</b> ont uniquement accès aux <b>aides à la rénovation d\'ampleur</b>',
        montantGesteModalButton: 'Voir les montants des aides par geste de travaux',
        travauxIntro: 'MaPrimeRénov’ finance des gestes de travaux et des rénovations d’ampleur.',
        chauffageAnnotation: 'Le remplacement de systèmes de chauffage donne accès en complément aux aides par geste pour&nbsp;:<br>' +
          '- Isolation' +
          '<br>' +
          '- Remplacement de fenêtres simple vitrage' +
          '<br>' +
          '- Ventilation double flux',
        renoAmpleurAnnotation: 'Quand les travaux permettent de gagner à minima 2 classes énergétiques et sont accompagnés par Mon Accompagnateur Rénov\'.',
        obtention: {
          item1: 'MaPrimeRénov’ est délivrée par l’ANAH (Agence Nationale pour l’Habitat)',
          item2: 'La demande de prime est à réaliser par le propriétaire ou son mandataire sur le site maprimerenov.gouv.fr',
          item3: 'L’ANAH instruit la demande et confirme l’attribution de la prime',
          item4: 'A la fin du chantier, l’ANAH verse la prime'
        }
      },
      tva55: {
        title: 'TVA 5,5%',
        intro: '<b>Les travaux de rénovation énergétique bénéficient d’une TVA ultra réduite avec un taux de 5,5% contre 10% pour les travaux de rénovation non énergétique.</b>',
        logement: 'Pour tous les logements achevés depuis plus de 2 ans',
        qui: 'Tous les propriétaires et les locataires',
        travauxIntro: 'Des travaux de rénovation énergétique respectant les exigences de performance définies par MaPrimeRénov\'\xa0:',
        obtention: 'La TVA réduite est appliquée directement sur le devis.'
      },
      cee: {
        title: 'Les primes CEE',
        intro: '<b>Les primes CEE</b> sont délivrées par les fournisseurs d’énergies contre des «&nbsp;certificats d’économies d’énergie&nbsp;».<br>' +
          '<br>' +
          'L’ambition de ce dispositif est d’obliger les fournisseurs d’énergie à faire réaliser des économies d’énergie à leurs clients, en les incitant à réaliser des travaux de rénovation énergétiques. Ces certificats correspondent aux économies réalisées suite à ces travaux de rénovation énergétique. \n' +
          '<br>' +
          '<b>Les primes «&nbsp;Coup de pouce&nbsp;»</b> sont des primes CEE bonifiées pour certains travaux.  ',
        logement: 'Pour tous les logements achevés depuis plus de 2 ans',
        qui: 'Tous les propriétaires et les locataires',
        montant1: '<b>Les primes CEE sont variables.</b> Leur montant dépend des économies générées par les travaux de rénovation énergétique. Pour chaque travaux, un montant d’économie d’énergie est défini et est converti en euros en général mais il peut aussi être converti en bon d’achat, en prêt bonifié ou encore en service ou produit offert.',
        montant2: '<b>Les primes «&nbsp;Coup de pouce&nbsp;» sont forfaitaires.</b> Le montant dépend des travaux réalisés et du niveau de revenus du ménage.',
        travaux: 'Des travaux réalisés par des <b>professionnels RGE (dans le cas du résidentiel)</b> et respectant des exigences de performance&nbsp;pour des travaux de&nbsp;:',
        renoGlobalCollectif: 'Rénovation globale collective',
        renoAmpleurAnnotation: 'quand les travaux permettent un gain d\'au moins 2 classes uniquement pour les propriétaire personnes morales ou de résidences secondaires.',
        renoGlobalCollectifAnnotation: 'quand les travaux permettent de réaliser au moins 35% d\'économies d\'énergie sur un bâtiment résidentiel collectif.',
        obtention: {
          item1: 'La demande doit être faite avant signature du devis auprès d’un fournisseur d’énergie ou d’un délégataire.',
          item2: 'Si le dossier est conforme aux exigences et contrôles éventuels, la prime est versée à la validation du dossier.'
        }
      },
      ecoptz: {
        title: 'ÉCO-PRÊT À TAUX ZÉRO',
        intro: 'Ce prêt à 0% sur 20 ans maximum est accordé par les organismes bancaires. Il ne peut être demandé qu’une seule fois par logement.',
        logement: 'Pour toutes les résidences principales achevés depuis plus de 2 ans',
        qui: 'Tous les propriétaires',
        montant1: '<b>50&nbsp;000&nbsp;€ sur 20 ans</b> pour une rénovation d\'ampleur ou pour un geste de travaux financé par MaPrimeRénov\' pour les ménages modestes ou très modestes',
        montant2: '<b>30&nbsp;000&nbsp;€ sur 15 ans</b> pour un bouquet de 3 travaux',
        montant3: '<b>25&nbsp;000&nbsp;€ sur 15 ans</b> pour un bouquet de 2 travaux',
        montant4: '<b>15&nbsp;000&nbsp;€ sur 15 ans</b> pour 1 poste de travaux',
        montant5: '<b>7&nbsp;000&nbsp;€ sur 15 ans</b> pour le remplacement de menuiseries simple vitrage',
        travauxIntro: 'Des travaux réalisés par des <b>professionnels RGE</b> et respectant des exigences de performance pour des travaux de&nbsp;:',
        renoAmpleurAnnotation: 'Quand les travaux permettent de gagner au moins 2 classes énergétiques ou de réaliser 35% d\'économies d’énergie',
        obtention: {
          item1: 'La demande est à faire auprès d’un organisme bancaire'
        }
      },
      aideLocale: {
        title: 'Aides locales',
        intro: 'Sur les territoires, les collectivités publiques peuvent aussi financer la rénovation énergétique des logements.',
        collectiviteTitle: 'Quelles collectivités territoriales délivrent des aides\xa0?',
        collectivite: 'Régions, départements, métropoles, communauté d’agglomération et communes, chaque collectivité peut mettre en place un programme de financement de travaux de rénovation énergétique.',
        dispoTitle: 'Quelles sont les aides locales disponibles\xa0?',
        dispo1: '<b>Plus de 3 000 aides locales</b> sont recensées sur les territoires',
        dispo2: 'Les aides locales sont très variables car chaque collectivité définit ses propres règles.<br>' +
          '<a href="https://aidesauxtravaux.fr/?enseigne=oscar#/accueil" class="link" target="_blank" rel="noopener">OSCAR</a> met à votre disposition un annuaire qui recense l’ensemble des aides et leurs conditions.'
      }
    },
    cumul: {
      title: 'Les règles de cumul des aides',
      intro: 'Pour maîtriser les aides, il est nécessaire de connaître les règles de cumul et d’incompatibilité selon les types d’aides.',
      gestes: {
        title: 'Par geste de travaux',
        mprCoupPouce: 'Le cumul des aides MaPrimeRénov’ et des aides CEE ou « Coup de pouce » applique un <b>plafonnement</b> sur la dépense éligible <b>selon le niveau de revenu à hauteur de&nbsp;:</b>',
        mprAideLocale1: 'Le cumul des aides MaPrimeRénov’ et des aides locales <b>peut atteindre 100% de la dépense éligible.</b>',
        mprAideLocale2: 'Dans le cas où <b>le montant des aides cumulées dépasserait le plafond alors s’applique la règle d’écrêtement</b>, c’est-à-dire une diminution du montant de Ma Prime Rénov’.'
      },
      ampleur: {
        title: 'Pour une rénovation d’ampleur',
        mprCoupPouce: 'Les dispositifs MaPrimeRénov’ et CEE ou Coup de pouce <b>ne peuvent jamais se cumuler.</b><br>' +
          'Dans le cadre de MaPrimeRénov Parcours accompagné, les CEE et Coup de Pouce "Rénovation d\'ampleur" sont valorisés directement par l\'ANAH.<br>',
        mprAideLocale: 'Le dispositif MaPrimeRénov’ et les dispositifs d’aides locales <b>peuvent se cumuler dans la limite d’un plafond</b> différent selon le niveau de revenus en coût TTC des travaux&nbsp;:',
        plafond: 'Dans le cas où <b>le montant des aides cumulées dépasserait le plafond alors s’applique la règle d’écrêtement</b>, c’est-à-dire une diminution du montant de Ma Prime Rénov’.'
      },
      incompat: {
        title: 'Incompatibilité des aides par geste et pour une rénovation d\'ampleur',
        content: 'Que ce soit à l’intérieur d’un même dispositif ou entre les dispositifs MaPrimeRénov’ et le dispositif des CEE ou Coup de pouce, <b>il est impossible de cumuler des aides par geste de travaux et des aides à la rénovation d’ampleur.</b>'
      }
    },
    activity: {
      title: 'Sélectionnez les aides concernées par l’affirmation',
      intro: {
        title: 'Connaître les spécificités des aides sur le bout des doigts',
        rules: 'L’activité contient 9 affirmations. Pour chacune d’entre elles, <b>sélectionnez la ou les aides correspondantes.</b>'
      },
      ressourceLink: 'Accéder à la liste des aides disponibles',
      questions: {
        question1: '«\xa0Je suis uniquement accessible aux propriétaires\xa0»',
        question2: '«\xa0Je suis uniquement accessible pour les logements achevés depuis plus de 15 ans\xa0»',
        question3: '«\xa0Je suis financée par des acteurs privés\xa0»',
        question4: '«\xa0Je donne une prime forfaitaire en euros\xa0»',
        question5: '«\xa0Je finance une rénovation d\'ampleur pour des propriétaires occupants de résidence principale\xa0»',
        question6: '«\xa0Les travaux que je finance doivent répondre à des exigences de performance énergétique\xa0»',
        question7: '«\xa0Le montant de ma prime dépend du niveau de revenus des ménages\xa0»',
        question8: '«\xa0Je finance l’isolation des combles perdus\xa0»'
      },
      corrections: {
        correction1: '<b>MaPrimeRénov’ et l’éco-prêt à taux zéro sont réservés aux personnes physiques propriétaires occupants ou bailleurs d’une résidence principale.</b> Les primes CEE ou Coup de pouce et la TVA 5,5% sont accessibles à tous les clients qui commandent des travaux de rénovation énergétique éligibles.',
        correction2: '<b>Depuis le 1er janvier 2022, MaPrimeRénov’ est réservée aux logements datant de plus de 15 ans.</b> Les autres aides sont ouvertes à tous les logements de plus de 2 ans. ',
        correction3: '<b>Les aides CEE et Coup de pouce sont versées par les obligés et délégataires et l’éco-prêt à taux zéro par certains établissements bancaires.</b> Les autres aides sont financées par des fonds publics.',
        correction4: '<b>Seules les aides MaprimeRénov’ et Coup de pouce sont des primes forfaitaires en euros.</b> L’éco-prêt à taux zéro s’exprime en forfait mais n’est pas une prime.',
        correction5: 'Toutes les aides financent la rénovation d\'ampleur mais les CEE ou Coup de pouce "Rénovation d\'ampleur" ne s\'adressent qu\'aux propriétaires de résidence secondaire ou aux personnes morales. Les CEE et le Coup de Pouce sont directement valorisés par l\'ANAH dans le cadre de Ma Prime Rénov\' Parcours Accompagné.',
        correction6: '<b>Toutes les aides sont conditionnées à des exigences de performance</b>, celles-ci sont susceptibles de varier entre les différents dispositifs.',
        correction7: '<b>Seuls les dispositifs MaprimeRénov’ et Coup de pouce proposent des montants différents d’aides en fonction du revenu des ménages.</b>',
        correction8: 'L’isolation des combles perdus était largement financée par les aides Coup de pouce mais n’est désormais plus éligible à cette prime. <b>MaPrimeRénov’ finance l\'intervention uniquement par l\'aide à la rénovation d\'ampleur.</b>'
      }
    }
  },
  part3: {
    intro: {
      objectif1: 'Appréhender les grands principes du dispositif MaPrimeRénov’'
    },
    lesson1: {
      tab: 'histoire',
      title: 'MaPrimeRénov’,<br>si l’histoire m\'était contée',
      videoButton: 'Découvrir l’histoire de MaPrimeRénov’ en vidéo',
      help: 'Regardez la vidéo ci-dessus avant d’accéder à la suite',
      majBlock: {
        title: 'Mise à jour 2024',
        intro: 'MaPrimeRénov\' est reconduit en 2024 avec un budget de 3 milliards d\'euros soit une hausse de 20% par rapport à 2023.',
        intro2: 'Les grandes orientations pour 2024 sont\xa0:',
        content1: 'Accélérer la rénovation performante des logements avec des primes allant de 12 500€ à 63 000 € selon le niveau de performance énergétique et le revenu des ménages.',
        content2: 'Décarboner les logements avec des primes à la hausse par rapport à 2023.',
        content3: 'Accompagner les ménages dans leurs démarches techniques, administratives et financière avec le recours obligatoire à Mon Accompagnateur Rénov\' pour toute demande d\'aide à la rénovation performante.'
      }
    },
    lesson2: {
      tab: 'acteurs',
      title: 'MaPrimeRénov’<br>et ses acteurs',
      titleAnnotation: 'Sélectionnez un personnage pour en apprendre plus sur son rôle dans le dispositif MaPrimeRénov’',
      block1: {
        tab1: {
          title: 'L’État Français',
          annotation: 'dans le rôle de décideur / financeur',
          content: '<b>L’État a impulsé le dispositif MaPrimeRénov’</b> comme aide publique principale <b>pour encourager la rénovation énergétique des logements.</b>'
        },
        tab2: {
          title: 'L’ANAH',
          annotation: 'dans le rôle d’organisateur',
          content: '<b>L’ANAH est en charge de l’enregistrement des demandes et de la distribution de MaPrimeRénov’.</b><br>' +
            '<br>' +
            'Depuis 50 ans sa mission est de rénover l’état des logements privés déjà existants dans le but de lutter contre les inégalités sociales.'
        },
        tab3: {
          title: 'Les ménages et copropriétés',
          annotation: 'dans le rôle des bénéficiaires / maître d\'ouvrage',
          content: '<b>Tous les propriétaires de résidences principales  sont éligibles à MaPrimeRénov’.</b>',
          drawer: {
            title: 'À propos des copropriétés',
            content: 'Les copropriétés sont éligibles à <b>MaPrimeRénov’ Copropriété</b> pour des travaux portant sur les parties communes et sur les parties privatives d’intérêt général permettant d’atteindre 35% de gain énergétique.<br>' +
              '<br>' +
              'Dans ce cas, c’est le syndic qui est bénéficiaire de l’aide. Il est obligatoirement accompagné par un assistant à maîtrise d’ouvrage.'
          }
        },
        tab4: {
          title: 'Les artisans et entreprises du bâtiment RGE',
          annotation: 'dans le rôle des responsables de travaux',
          content: 'Les artisans ou entreprises du bâtiment RGE sont les garants de la réalisation des travaux.<br>' +
            '<b>Seuls les travaux réalisés par des artisans et des entreprises du bâtiment RGE sont éligibles à MaPrimeRénov’.</b>',
          drawer1: {
            title: 'À propos de la qualification RGE',
            content: 'Le label RGE (Reconnu Garant de l’Environnement) est un signe de qualité délivré à un artisan ou une entreprise du bâtiment qui remplit des critères de qualification et  d’équipements. Il existe des qualifications RGE pour chaque domaine de travaux (Qualit’enr, QualiPAC, Qualibois…)'
          }
        },
        tab5: {
          title: 'Mon Accompagnateur Rénov’',
          annotation: 'dans le rôle du tiers de confiance',
          content: 'Mon Accompagnateur Rénov’ a pour mission d’aider les ménages dans leur parcours de rénovation, de la définition des travaux à leur réception en apportant un appui technique, administratif, financier et social.',
          drawer1: {
            title: 'A propos de Mon Accompagnateur Rénov’',
            content: 'Seuls les professionnels éligibles et agréés par l’Anah peuvent intervenir comme Mon Accompagnateur Rénov’.',
            content2: 'La liste des professionnels agréés est accessible sur le site de FranceRénov’'
          }
        },
        tab6: {
          title: 'Le mandataire',
          annotation: 'dans le rôle de l\'accompagnateur de la demande du bénéficiaire / maître d\'ouvrage',
          content: '<b>Le mandataire est désigné par le bénéficiaire pour administrer la demande d\'aide</b>. S’il est habilité, <b>le mandataire peut aussi être mandataire financier</b>. Dans ce cas, il avance le montant de l’aide et percevra directement la prime à l’issue des travaux.',
          drawer1: {
            title: 'Qui peut être mandataire\xa0?',
            content: 'Le rôle de mandataire peut être assuré par <br>' +
              '- un conseiller France Rénov’<br>' +
              '- un délégataire CEE<br>' +
              '- une collectivité<br>' +
              '- un artisan ou une entreprise du bâtiment RGE<br>' +
              '- un fournisseur d’équipements et matériaux<br>' +
              '- un acteur de l’accompagnement<br>' +
              '- une personne de l’entourage<br>'
          }
        }
      }
    },
    lesson3: {
      tab: 'couleurs',
      title: 'La palette de couleurs MaPrimeRénov’',
      titleAnnotation: '<b>MaPrimeRénov’</b> utilise <b>une palette de 4 couleurs pour différencier les niveaux de revenus</b> des ménages. C’est cette palette qui <b>détermine l’accès aux primes ainsi que leur montant.</b>',
      mpr: {
        bleu: {
          label: 'Très\xa0modestes',
          color: 'bleu'
        },
        jaune: {
          label: 'Modestes',
          color: 'Jaune'
        },
        violet: {
          label: 'Intermédiaires',
          color: 'violet'
        },
        rose: {
          label: 'Supérieurs',
          color: 'rose'
        }
      },
      block2: {
        title: 'Les informations nécessaires pour connaître le niveau de revenus d’un ménage\xa0:',
        people: 'Combien de personnes composent le ménage\xa0?',
        money: 'Quelle est la somme des revenus fiscaux de référence (RFR) des personnes composant le ménage\xa0?',
        pinnedHome: 'Le lieu de résidence est-il en Île-de-France ou en province\xa0?',
        modalButton: 'Consulter le tableau des échelons de revenus',
        modalTitle: ''
      },
      blockGame: {
        title: 'Quelle est la couleur MaPrimeRénov’ de ce ménage\xa0?',
        questions: {
          q1: {
            situation: 'Célibataire',
            money: 'Au SMIC 15\xa0230\xa0€\xa0/\xa0an',
            location: 'Nanterres 92000'
          },
          q2: {
            situation: 'Couple de retraités',
            money: 'Revenus de 44\xa0000\xa0€',
            location: 'Nantes 44000'
          },
          q3: {
            situation: 'Couple avec 4 enfants',
            money: 'Revenus de 51\xa0000\xa0€',
            location: 'Fécamp 76400'
          }
        }
      }
    }
  },
  part4: {
    intro: {
      objectif1: 'Appréhender les grands principes du dispositif CEE’',
      objectif2: 'Comprendre le calcul des économies d’énergie'
    },
    lesson1: {
      tab: 'histoire',
      title: 'Les CEE ou l’obligation d’économies d’énergie',
      videoButton: 'Comprendre les CEE en vidéo'
    },
    lesson2: {
      tab: 'calcul',
      title: 'Comment est calculé le montant d’une prime CEE',
      titleAnnotation: 'Si la prime CEE est généralement exprimée en euros, <b>l’unité de compte du CEE est le kWh cumac. Chaque chantier de rénovation équivaut à un certain nombre de kWh cumac.\n' +
        'Ce montant de kWh cumac est librement valorisé par les obligés (fournisseurs d’énergie)</b> généralement en euros mais aussi en bons d’achat ou en services.',
      block1: {
        title: 'Qu’est-ce qu’un kWh cumac\xa0?',
        annotation: 'Un kWh cumac, c’est l’énergie économisée sur la durée de vie de l’installation et actualisée pour prendre en compte ses pertes de performance.',
        blockTitle: 'Calcul des kWh cumac d’une intervention',
        blockContent: 'Le nombre de kWh cumac est défini pour chaque travaux de rénovation énergétique. Dans le jargon CEE, on appelle ça une « fiche d’opération standardisée ».<br>' +
          '<br>' +
          'Il y a environ 200 fiches d\'opération standardisée dont plus de 100 dans le résidentiel et le tertiaire.<br>' +
          '<br>' +
          'Pour une même opération, le nombre kWh cumac est différent selon la zone climatique mais peut aussi varier selon :<br>' +
          '- le type de logement, maison ou appartement<br>' +
          '- la surface du logement <br>' +
          '- l’énergie de chauffage du logement  <br>' +
          '- l’efficacité énergétique du système'
      },
      block2: {
        title: 'Comment sont calculées les primes CEE\xa0?',
        annotation: 'La valeur du CEE est une valeur de type boursière.<br>' +
          'Toutefois, chaque obligé est libre de se conformer ou non au taux de la bourse des CEE, selon qu’il ait produit suffisamment de CEE ou non. <b>Chaque obligé fixe donc librement une valorisation du CEE pour octroyer ses primes.</b> Cette valorisation s’exprime en € / MWhc.',
        blockTitle: 'Calcul du montant d’une prime CEE',
        blockContent: 'Les kWh doivent être divisés par 1 000 pour les convertir en MWhc',
        operation: {
          title: 'exemple',
          left: '12\xa0MWh\xa0cumac',
          right: '5\xa0€\xa0/\xa0MWhc',
          result: '60\xa0€',
          annotation: '12\xa0000\xa0kWhc\xa0/\xa01\xa0000'
        }
      },
      gameBlock: {
        cumacModal: 'Voir la fiche d’opération standardisée',
        rightAnswer: 'la bonne réponse était…',
        good: 'Bravo\xa0!',
        bad: 'Perdu\xa0!',
        answerLabel: 'votre réponse',
        unitCumac: 'kWh\xa0cumac',
        unitEuro: '\xa0€',
        question1: {
          label: 'Quelle est l’économie réalisée\xa0?',
          tip1: 'Installation de <b>10 fenêtres<br>' +
            'et 3 portes-fenêtres soit 20m²</b>',
          tip3: 'Aix-en-Provence<br>' +
            '(Zone <b>H3</b>)'
        },
        question2: {
          label: 'Quel serait le montant de la prime\xa0?',
          tip1: 'Économies réalisées<br>' +
            '<b>42&nbsp;000&nbsp;kWhc</b>',
          tip2: 'Valorisation de l’obligé<br>' +
            '<b>5&nbsp;€&nbsp;/&nbsp;MWhc</b>',
          resultAnnotation: '(42 000 / 1 000) x 5'
        },
        question3: {
          label: 'Quelle est l’économie réalisée\xa0?',
          tip1: 'Isolation de <b>5&nbsp;000&nbsp;m<sup>2</sup> de toitures terrasses</b>',
          tip2: '<b>Hôpital</b> Salpêtrière à Paris<br>' +
            '(Zone <b>H1</b>)',
          tip3: 'Chauffé avec une <b>chaudière biomasse</b>'
        },
        question4: {
          label: 'Quel serait le montant de la prime\xa0?',
          tip1: 'Économies réalisées<br>' +
            '<b>18&nbsp;200&nbsp;000&nbsp;kWhc</b>',
          tip2: 'Valorisation de l’obligé<br>' +
            '<b>5&nbsp;€&nbsp;/&nbsp;MWhc</b>',
          resultAnnotation: '(18 200 000 / 1000) x 5 '
        }
      }
    },
    lesson3: {
      tab: 'acteurs',
      title: 'Le rôle actif et incitatif des obligés',
      videoButtonLabel: 'Comprendre le rôle des obligés en vidéo',
      block1: {
        title: 'Les acteurs du dispositif CEE'
      },
      acteurs: {
        etat: {
          label: 'L’État via la DGEC',
          content: 'pilote le dispositif'
        },
        pncee: {
          label: '<abbr title="Pôle National des Certificats d\'Économies d\'Énergie">Le PNCEE</abbr>',
          content: 'instruit les dossiers et délivre les CEE'
        },
        oblige: {
          label: 'Les obligés',
          content: 'doivent inciter à réaliser des économies d’énergie et promouvoir l’efficacité énergétique'
        },
        delegataire: {
          label: 'Les délégataires',
          content: 'peuvent administrer des demandes CEE pour le compte de certains obligés'
        },
        artisan: {
          label: 'Les artisans et entreprises du bâtiment',
          content: 'prescrivent et réalisent les travaux d’amélioration de performance énergétique'
        },
        beneficiaire: {
          label: 'Les bénéficiaires / maître d\'ouvrage',
          content: 'obtiennent une prime lors de la réalisation de travaux de rénovation énergétique'
        }
      }
    }
  },
  part5: {
    intro: {
      objectif: 'Identifier les modalités pour valoriser les aides publiques et privées'
    },
    lesson: {
      title: 'Choisir son niveau d’implication',
      titleAnnotation: 'Les professionnels du bâtiment peuvent choisir de s’impliquer plus ou moins dans la valorisation des travaux de rénovation énergétique. Leur niveau d’implication <b>leur permet de proposer des offres plus ou moins attractives</b> mais valoriser les aides financières représente cependant <b>un investissement et des risques à mesurer.</b>',
      bubbleTitle: 'avantage concurrentiel',
      blockNiveau1: {
        label: 'Niveau de base',
        title: 'Réaliser les travaux aidés',
        content: 'Pour valoriser des aides à la rénovation énergétique, le professionnel doit <b>détenir les qualifications requises, respecter les règles de l’art et s’engager à fournir des documents conformes aux exigences des différentes aides mobilisées.</b>',
        bubbleContent: 'Rend les chantiers éligibles aux aides à la rénovation énergétique',
        warning: 'Le professionnel doit <b>se tenir informé des exigences de performance</b> requises et adapter ses prescriptions de travaux.'
      },
      blockNiveau2: {
        label: 'Niveau intermédiaire',
        title: 'Gérer les dossiers',
        content: 'En plus de la réalisation des travaux, le professionnel peut <b>s’occuper de la gestion du ou des dossiers de demande d’aides</b> pour le compte du bénéficiaire / maître d\'ouvrage.',
        bubbleContent: 'Libère les bénéficiaires de la charge administrative',
        warning: 'Le suivi des dossiers exige de la <b>rigueur administrative et du temps à allouer</b> à cette mission.'
      },
      blockNiveau3: {
        label: 'Niveau max',
        title: 'Avancer les aides',
        content: 'Le professionnel peut également <b>prendre en charge l’aspect financier.</b> Dans ce cas, il <b>déduit l\'aide de son devis</b> quand c\'est possible.',
        bubbleContent: 'Sécurise et réduit l’investissement des bénéficiaires / maître d\'ouvrage.',
        warning: 'Le professionnel <b>doit être financièrement stable et avoir une trésorerie</b> importante car les aides peuvent atteindre plusieurs milliers d’euros et lui être reversées après un certain délai.'
      },
      block2: {
        title: 'Gestion d’un dossier MaPrimeRénov’',
        content: '<b>C’est toujours le bénéficiaire / maître d\'ouvrage qui initie la demande d’aide.</b> Il crée pour cela son compte sur internet. Il peut ensuite désigner un mandataire administratif (qui gère le dossier) et/ou financier (qui avance et perçoit la prime).'
      },
      block3: {
        title: 'Gestion d’un dossier CEE',
        content: 'Pour gérer les dossiers CEE et faire bénéficier des primes CEE, l’artisan ou l’entreprise du bâtiment doit porter le Rôle Actif et Incitatif pour le compte d’un obligé ou délégataire. Pour cela, <b>il doit avoir contractualisé en amont avec un obligé ou délégataire.' +
        ' Il gère alors l’ensemble du dossier administratif</b> et peut déduire directement la prime CEE de ses devis et factures.'
      }
    }
  }
}

export default module1Messages
